import React  from "react"
import Layout from "../components/UI/Layout"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Seo from "../components/Seo"
import { Link } from "gatsby"
import Typography from "../components/Utils/Typography"


import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import useSiteMetadata from "../components/Data/useSiteMetadata"


const styles = theme => ({
  h1: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,      
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 24,      
    },    
  },
  more: {
    marginTop: theme.spacing(2),
  },

})


function Datenschutz(props) {
  const { classes } = props
  const { siteDomain, publisher,publisher_salutation,organization} = useSiteMetadata()
  /* const theme = useTheme() */
  //const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  return (
    <Layout>
      
       <Seo  title={`Datenschutzerklärung" description="Angaben zum Datenschutz der ${organization.name}  für die Website ${siteDomain} und assozierte Social Media Seiten.`}/>
      <Container>
        <Box mt={7} mb={12}>
          <Typography variant="h1" gutterBottom marked="center" align="center"  className={classes.h1} >
          Datenschutzerklärung
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            align="center"
            style={{ height: "5vh" }}
          >
          </Typography>
        </Box>

        <Typography
            variant="body1"
            gutterBottom
            align="center"            
          >
        
      <div>
        <h2>
         
          <p>           
          
          der {organization.name} für die Domäne {siteDomain} und die zugehörigen Social Media Seiten bei Facebook, Twitter und Instagramm. 
          </p>
        </h2>
        <article id="Datenschutz" style={{textAlign: 'justify'}}>
        <p>Diese Datenschutzerklärung informiert Sie über den Umgang mit Ihren Daten, wenn Sie diese Website oder eine andere Webseite der Domäne {siteDomain} besuchen oder unsere Social Media nutzen.
        Bei den Social Media Diensten sind die jeweiligen Datenschutzerklärungen der Anbieter zusätzlich zu beachten, ausführliche Informationen erhalten Sie weiter unten. </p>

        <h2><span style={{color: '#CDCDCD'}}>1.     Name und Kontaktdaten der Verantwortlichen</span></h2>
        <p>Verantwortlich für die Verarbeitung Ihrer personenbezogenen Daten ist {publisher_salutation} {publisher}, {organization.street}, {organization.postcode} {organization.city}, Telefon: {organization.phone}, E-Mail: {organization.email}</p>
        <h2><span style={{color: '#CDCDCD'}}>2.    Webhosting</span></h2>
        <p>
          Der Betrieb dieser Website erfolgt über ein Webhosting-Unternehmen, das die  Infrastruktur und den Speicherplatz für diese Website und die E-Mail-Postfächer auf seinen Servern in Deutschland zur Verfügung stellt.
          Das Unternehmen übernimmt die Wartung, den technischen Support sowie den Anlagenbetrieb und verarbeitet in unserem Auftrag personenbezogene Daten, die von Ihnen bei der  Nutzung dieser Website entstehen.
          Mit dem Unternehmen wurde ein Vertrag zur Auftragsverarbeitung gemäß Art. 28 DSGVO geschlossen.
          Im Übrigen ist es ein berechtigtes Interesse gemäß Art. 6 Abs. 1 S. 1 f der EU-Datenschutz-Grundverordnung (DSGVO) sicherzustellen, dass eine Website funktionsfähig ist und sicher betrieben wird.
        </p>
        <h2><span style={{color: '#CDCDCD'}}>3.     Besuch dieser Website</span></h2>
        <p>Wenn Sie diese Website besuchen, werden von uns bzw. dem Webhosting-Unternehmen die folgenden Zugriffsdaten erhoben und vorübergehend in einer sogenannten Logdatei gespeichert:</p>
        <ul>
          <li>die IP-Adresse des von Ihnen genutzten Rechners,</li>
          <li>Datum und Uhrzeit Ihres Besuchs,</li>
          <li>die Internetseiten, die Sie in diesem Webauftritt besuchen,</li>
          <li>die Internetseite, von der aus Sie auf diese Website gelangt sind (Referrer),</li>
          <li>der von Ihnen verwendete Browsertyp und die Version des Browsers sowie</li>
          <li>das Betriebssystem Ihres Rechners.</li>
        </ul>
        <p>Diese Daten werden dazu genutzt, um den Verbindungsaufbau zur Website sicherzustellen und um die Systemsicherheit zu gewährleisten. Die Zugriffsdaten werden nicht dazu genutzt, um einen Bezug zu Ihrer Person herzustellen. Aus den genannten Zwecken ergibt sich mein berechtigtes Interesse zur Datenverarbeitung gemäß Art. 6 Absatz 1 Satz 1 f DSGVO.</p>
        <p>Die Daten werden gelöscht, sobald sie nicht mehr für die genannten Zwecke benötigt werden. Die Daten, die für den Verbindungsaufbau zur Website erforderlich sind, werden gelöscht, sobald Sie meine Website verlassen. Im Übrigen werden die Daten in den Logdateien spätestens nach sieben Tagen gelöscht.</p>
        <h2><span style={{color: '#CDCDCD'}}>4.     Datensicherheit</span></h2>
        <p>Auf dieser Website wird das Verschlüsselungsverfahren SSL (Secure Socket Layer) eingesetzt. Dieses Verfahren schützt Ihre personenbezogenen Daten vor dem Zugriff Dritter während der Übertragung im Internet und entspricht dem aktuellen Stand der Technik. Sie können an der im Browser angezeigten Internetadresse erkennen, ob die Verbindung SSL-gesichert ist: Anstelle von „http://“ finden Sie dort bei verschlüsselter Verbindung http<strong>s</strong>://</p>
        <p>In manchen Browsern wie beispielsweise Mozilla Firefox sehen Sie zusätzlich das Bild eines Vorhängeschlosses vor der Internetadresse.</p>
        <p>Flankierend werden geeignete technische und organisatorische Maßnahmen getroffen, um Ihre Daten zu sichern sowie gegen zufällige oder vorsätzliche Manipulationen zu schützen, (teilweisen) Verlust, Zerstörung und gegen den unbefugten Zugriff Dritter. Die Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung laufend angepasst.</p>
        <h2><span style={{color: '#CDCDCD'}}>5.     Nachrichten via Kontaktformular</span></h2>
        <p>Sie können sich mit uns auf verschiedene Arten in Verbindung setzen, beispielsweise per Telefon oder E-Mail. Sie können uns auch eine Nachricht über das Online-Kontaktformular auf dieser Website senden. Daten, die dabei verarbeitet werden, sind Ihr Name und Ihre E-Mail-Adresse sowie die Daten, die Sie  zusätzlich in Ihrer Nachricht mitteilen. Diese Daten werden gemäß dem Zweck Ihrer Mitteilung verarbeitet.</p>
        <p>Rechtsgrundlage für die Datenverarbeitung ist das berechtigtes Interesse an der Beantwortung und Bearbeitung von Kontaktanfragen gemäß Art. 6 Abs. 1 Satz 1 f DSGVO.</p>
        <p>Wenn anlässlich Ihrer Kontaktaufnahme ein Vertragsverhältnis angebahnt wird,  werden die Daten zugleich zur Durchführung vorvertraglicher Maßnahmen bzw. zur Vertragserfüllung verarbeitet; dann erfolgt die Datenverarbeitung gemäß Art. 6 Abs. 1 S. 1 b DSGVO.</p>
        <p>Sobald sich Ihr Anliegen erledigt hat, werden Ihre  personenbezogenen Daten gelöscht, sofern gegen die Löschung keine gesetzlichen, insbesondere steuerrechtlichen Aufbewahrungspflichten sprechen und Sie mir keine ausdrückliche Einwilligung in eine weitere Nutzung erteilt haben.</p>
        <h2><span style={{color: '#CDCDCD'}}>6.     Vertragsdurchführung</span></h2>
        <p>Bestimmte personenbezogene Daten werden von uns im Rahmen der vertraglich vereinbarten Behandlung verarbeitet. Wenn Sie mit mir zum Beispiel ein Beratungsgespäch führen oder sich bei uns in Behandlung begegeben, werden Ihr Name, Ihre Adresse, E-Mail-Adresse und Telefonnummer, ggfs. auch Faxnummer verarbeitet.</p>
        <p>
          Weitere personenbezogene Daten fallen im Rahmen der ärztlichen Behandlung an und werden in ihrer Patientenakte festgehalten und entsprechend der gesetzlichen Vorschriften behandelt.
          Sofern Sie in keine weitere Nutzung eingewilligt haben, werden die Daten ausschließlich für die Durchführung der Behandlung genutzt. Die Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 b DSGVO.
        </p>
        <p>Nach Beendigung der Vertragsabwicklung werden Ihre Daten gelöscht, soweit keine gesetzlichen Regelungen wie beispielsweise steuerrechtliche Pflichten entgegenstehen und Sie mir keine ausdrückliche Einwilligung in eine weitere Nutzung erteilt haben.</p>
        <h2><span style={{color: '#CDCDCD'}}>7.     Übermittlung von Daten an Dritte</span></h2>
        <p>Eine Übermittlung Ihrer personenbezogenen Daten an Dritte findet nur in den folgenden Fällen statt:</p>
        <ul>
          <li>Sie haben mir hierzu Ihre Einwilligung gemäß Art. 6 Absatz 1 Satz 1 a DSGVO gegeben.</li>
          <li>Die Weitergabe ist gesetzlich zulässig und gemäß Art. 6 Abs. 1 S. 1 b DSGVO für die Abwicklung eines Vertragsverhältnisses mit Ihnen erforderlich.</li>
          <li>Die Weitergabe ist gemäß Art. 6 Abs. 1 S. 1 c DSGVO erforderlich zur Erfüllung einer rechtlichen Verpflichtung, der ich unterliege.</li>
          <li>Die Weitergabe ist gemäß Art. 6 Abs. 1 S. 1 f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich und es besteht kein Grund zur Annahme, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.</li>
          <li>Die Weitergabe erfolgt gemäß Art. 28 DSGVO auf der Grundlage eines Vertrags zur Auftragsverarbeitung.</li>
        </ul>
        <h2><span style={{color: '#CDCDCD'}}>8.     Cookies</span></h2>
        Auf dieser Seite werden keine Cookies eingesetzt. Gegebenfalls werden im Rahmen von eingebundenen Diensten, wie zum Beispiel OpenStreetMap, von diesen Cookies verwendet.
        <h2><span style={{color: '#CDCDCD'}}>9.     Matomo</span></h2>
        <p>Auf dieser Website wird Matomo eingesetzt, eine Open Source Webanalyse Software. Die Software dient dazu, Ihren Besuch auf dieser Website statistisch auszuwerten. So wird zum Beispiel anonymisiert festgehalten, welche Internetseiten Sie innerhalb dieses Webauftritts aufgerufen haben, wie lange Sie auf der Seite geblieben sind oder welche Internetseite Sie aufgerufen haben, bevor Sie diese Website verlassen. Diese Analyse dient dazu, die Inhalte meiner Website zu optimieren und für Sie informativer und nutzerfreundlicher zu gestalten. Hierfür werden pseudonymisierte Nutzungsprofile erstellt und Cookies verwendet (zu Cookies s. oben Ziffer 8). Die Cookies ermöglichen somit die Analyse der Nutzung der Website. Sie werden für die Dauer einer Woche gespeichert und anschließend automatisch gelöscht. Die folgenden Informationen werden im Cookie erfasst:</p>
        <ul>
          <li>Datum, Uhrzeit und die Dauer Ihres Besuchs auf meiner Website,</li>
          <li>die Häufigkeit Ihrer Besuche,</li>
          <li>die Internetseiten, die Sie innerhalb meiner Website aufrufen,</li>
          <li>der Referrer,</li>
          <li>der von Ihnen verwendete Browsertyp und die Version des Browsers sowie</li>
          <li>das Betriebssystem Ihres Rechners, mit dem Sie auf meine Website zugreifen,</li>
          <li>das Land, von dem aus Sie die Seiten aufrufen.</li>
        </ul>
        <p>Die  durch den Cookie erzeugten Informationen werden nicht an Dritte weitergegeben. Die Software habe ist so konfiguriert, dass die letzten Bytes Ihrer IP-Adresse maskiert werden. Mamoto speichert dadurch keine vollständigen IP-Adressen und Sie bleiben anonym.</p>
        <p>Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 Satz 1 f DSGVO. Das  berechtigtes Interesse an der Datenverarbeitung folgt aus dem vorgenannten Zweck der nutzerfreundlichen Gestaltung dieser Website.</p>
        <p><strong>Matomo deaktivieren</strong></p>
        <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern (s. oben Ziffer 8).</p>
        <p>
          Alternativ können Sie die Erfassung durch Matomo verhindern, indem Sie auf die Checkbox weiter unten klicken. Hierdurch wird ein Opt-out-Cookie in Ihrem Browser abgelegt, der die Erfassung Ihrer Daten durch Matomo verhindert. Beachten Sie, dass auch der Opt-Out-Cookie gelöscht wird, wenn Sie alle Cookies in Ihrem Browser löschen. Bei einem erneuten Besuch müssten Sie den Opt-Out-Cookie also wie oben beschrieben neu setzen:<br />
          <iframe title="Matamo Datenerfassung" style={{border: 0, height: '200px', width: '600px'}} src="https://analytics.estetiese.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily=" />
        </p>
        <p>Zusätzlich habe ich die „Do-not-Track“ Einstellung aktiviert. Wenn Sie in Ihrem  Browser „Ich möchte nicht getrackt werden“ eingestellt haben (Do-not-Track aktiviert), wird Mamoto Ihre Besuche nicht tracken.</p>
        <p>Die Datenschutzerklärung von Matomo können Sie über den folgenden Link einsehen: <a href="https://matomo.org/privacy-policy/" target="_blank" rel="noopener noreferrer">https://matomo.org/privacy-policy/</a></p>
        <h2><span id="shariff" style={{color: '#CDCDCD'}}>10.  Social Media Plugins</span></h2>
        <p>Sie können Inhalte, die Ihnen auf dieser Website gefallen, in den sozialen Netzwerken Facebook, Twitter order Instagram teilen. Dafür werden Plugins der Anbieter der jeweiligen Dienste verwendet. Wenn Sie auf das Plugin klicken, um einen meiner Beiträge über das Netzwerk zu teilen, wird eine Verbindung mit dem jeweiligen Dienst hergestellt. Dieser Beitrag wird dann in Ihrem Nutzerkonto gemäß Ihren Einstellungen zur Privatsphäre des Dienstes – z. B. nur einer bestimmten Personengruppe im Netzwerk oder öffentlich – sichtbar gemacht.</p>
        <p>Normalerweise werden von den Plugins sofort beim Aufruf der Internetseite Daten der Nutzer/innen an den Server des sozialen Netzwerks übermittelt – unabhängig davon, ob Sie auf das Plugin klicken bzw. tippen oder überhaupt als Nutzer/in in dem sozialen Netzwerk registriert sind. So können die Anbieter Ihr Surfverhalten verfolgen und für eigene Marketingzwecke auswerten (User Tracking). Um das zu vermeiden, wird auf der Praxis-Website <strong>Shariff</strong> eingesetzt. Mit Shariff wird die Verbindung zum Server des Dienstes erst hergestellt, wenn Sie auf das Plugin klicken.</p>
        <p>Shariff wird freundlicherweise von der Computerfachzeitschrift c’t und heise online als Open Source Software zur Verfügung gestellt. Mehr Informationen erhalten Sie auf den Seiten von Heise: <a href="http://www.heise.de/ct/artikel/Shariff-Social-Media-Buttons-mit-Datenschutz-2467514.html" target="_blank" rel="noopener noreferrer">http://www.heise.de/ct/artikel/Shariff-Social-Media-Buttons-mit-Datenschutz-2467514.html</a></p>
        <p>Ich setze die Plugins zu Werbezwecken ein. Durch das Teilen und Weiterempfehlen von Inhalten auf meiner Website in sozialen Netzwerken kann meine Praxis bekannter gemacht werden. Die Rechtsgrundlage ist Art. 6 Abs. 1 Satz 1 f DSGVO. Der Einsatz der Plugins zu Werbezwecken stellt mein berechtigtes Interesse im Sinne der DSGVO dar.</p>
        <p>Über die Plugins im Einzelnen:</p>
        <ul>
          <li><strong><span style={{color: '#CDCDCD'}}>Facebook</span></strong></li>
        </ul>
        <p>Auf der Website wird das Social Plugin von Facebook verwendet.</p>
        <p>Mit einem Klick auf die Facebook-„Gefällt-mir“-Schaltfläche können Sie Ihren Freunden in Ihrem Facebook-Profil zeigen, dass Ihnen ein Beitrag auf dieser Website gefällt. Erst mit einem Klick auf die Facebook-Schaltfläche stellt der Shariff-Button den direkten Kontakt zwischen Facebook und Ihnen her. Es wird eine Verbindung zum Server von Facebook aufgebaut und Daten wie die IP-Adresse Ihres Rechners und die von Ihnen besuchte Internetseite sowie Zeit und Datum des Seitenabrufs werden an Facebook übermittelt. Wenn Sie bei Facebook angemeldet sind, wird Facebook die Daten Ihrem Facebook-Profil zuordnen und dort anzeigen,  dass Ihnen ein Beitrag auf meiner Website gefällt.</p>
        <p>Facebook ist ein Dienst von Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Facebook Ireland Ltd. ist unter dem EU-US Privacy Shield zertifiziert, einem Abkommen, das die Einhaltung der in der EU geltenden Datenschutzvorschriften gewährleistet.</p>
        <p>Die Datenschutzerklärung von Facebook können Sie unter dem folgenden Link aufrufen: <a href="https://de-de.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">https://de-de.facebook.com/about/privacy/</a></p>
        <ul>
          <li><strong><span style={{color: '#CDCDCD'}}>Twitter</span></strong></li>
        </ul>
        <p>Auf dieser Website wird das Social Plugin des sozialen Netzwerks Twitter verwendet. Mit einem Klick auf die „Twitter“-Schaltfläche können Sie einen Beitrag mit Ihren Kontakten auf Twitter teilen. Sobald Sie auf die Twitter-Schaltfläche klicken, stellt der Shariff-Button den direkten Kontakt zwischen Twitter und Ihnen her. Es wird eine Verbindung zum Server von Twitter aufgebaut und Daten wie die IP-Adresse Ihres Rechners, die von Ihnen besuchte Internetseite sowie Zeit und Datum des Seitenabrufs werden an Twitter übermittelt.</p>
        <p>Sofern Sie bei Aktivierung des Plugins in Ihrem Twitter-Konto eingeloggt sind, werden die übermittelten Daten Ihrem Nutzerkonto zugeordnet und der geteilte Beitrag wird dort angezeigt.</p>
        <p>Twitter ist ein Dienst von Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Twitter Inc. ist unter dem EU-US Privacy Shield zertifiziert, einem Abkommen, das die Einhaltung der in der EU geltenden Datenschutzvorschriften gewährleistet.</p>
        <p>Die Datenschutzerklärung von Twitter können Sie unter dem folgenden Link aufrufen: <a href="https://twitter.com/de/privacy" target="_blank" rel="noopener noreferrer">https://twitter.com/de/privacy</a></p>
        <ul>
          <li><strong><span style={{color: '#8b0000'}}>XING</span></strong></li>
        </ul>
        <p>Auf dieser Website wird der „XING Share Button“ verwendet. XING ist ein Dienst von XING SE. Mit einem Klick auf die „XING“-Schaltfläche können Sie einen Beitrag mit Ihren Kontakten in Ihrem XING-Profil teilen. Sobald Sie auf die „XING“-Schaltfläche tippen oder klicken, stellt der Shariff-Button den direkten Kontakt zwischen XING SE und Ihnen her. Es wird eine Verbindung zu Servern von XING SE aufgebaut, mit denen die „XING Share-Button“-Funktionen (insbesondere die Berechnung/Anzeige des Zählerwerts) erbracht werden. Nach eigenen Angaben von XING werden keine personenbezogenen Daten von Ihnen über den Seitenaufruf gespeichert, insbesondere keine IP-Adressen. Es findet auch keine Auswertung Ihres Nutzungsverhaltens über die Verwendung von Cookies im Zusammenhang mit dem „XING Share-Button“ statt.</p>
        <p>XING ist ein Dienst von <strong>XING SE, </strong>Dammtorstraße 30, 20354 Hamburg, Deutschland. Die Datenschutzerklärung von XING finden Sie hier: <a href="https://www.xing.com/app/share?op=data_protection" target="_blank" rel="noopener noreferrer">https://www.xing.com/app/share?op=data_protection</a></p>
        <ul>
          <li><strong><span style={{color: '#8b0000'}}>LinkedIn</span></strong></li>
        </ul>
        <p>Auf dieser Website wird der „LinkedIn Share Button“ verwendet. LINKEDÍN ist ein Dienst von LinkedIn Ireland Unlimited Company. Mit einem Klick auf die „LinkedIn“-Schaltfläche können Sie einen Beitrag mit Ihren Kontakten in Ihrem XING-Profil teilen. Sobald Sie auf die „LinkedIn“-Schaltfläche tippen oder klicken, stellt der Shariff-Button den direkten Kontakt zwischen LinkedIn Ireland Unlimited Company und Ihnen her. Es wird eine Verbindung zu Servern von LinkedIn Ireland Unlimited Company aufgebaut, mit denen die „LinkedIn Share-Button“-Funktionen (insbesondere die Berechnung/Anzeige des Zählerwerts) erbracht werden. Nach eigenen Angaben von XING werden keine personenbezogenen Daten von Ihnen über den Seitenaufruf gespeichert, insbesondere keine IP-Adressen. Es findet auch keine Auswertung Ihres Nutzungsverhaltens über die Verwendung von Cookies im Zusammenhang mit dem „LinkedIn Share-Button“ statt.</p>
        <p>LinkedIn ist ein Dienst von <strong>LinkedIn Ireland Unlimited Company, </strong>Wilton Place, Dublin 2, Irland. Die Datenschutzerklärung von LinkedIn finden Sie hier: <a href="https://www.linkedin.com/legal/privacy-policy?trk=homepage-basic_footer-privacy-policy" target="_blank" rel="noopener noreferrer">https://www.linkedin.com/legal/privacy-policy?trk=homepage-basic_footer-privacy-policy</a></p>
        <ul>
          <li><strong><span style={{color: '#8b0000'}}>WhatsApp</span></strong></li>
        </ul>
        <p>Wenn Sie diese Seiten mit einem Mobilfunkgerät (z. B. Smartphone) aufrufen, können Sie Beiträge über Whatsapp empfehlen. Mit dem Antippen des WhatsApp-Buttons können Sie über WhatsApp eine Nachricht mit dem Link zum Beitrag an Ihre persönlichen WhatsApp-Kontakte versenden. Erst, wenn Sie auf den Button tippen, werden der Inhalt Ihrer Nachricht sowie die aufgerufene Seite an WhatsApp übermittelt.</p>
        <p>WhatsApp ist ein Dienst von WhatsApp Inc., WhatsApp Inc., 1601 Willow Road, Menlo Park, California 94025. WhatsApp Inc. ist unter dem EU-US Privacy Shield zertifiziert, einem Abkommen, das die Einhaltung der in der EU geltenden Datenschutzvorschriften gewährleistet. Die Datenschutzerklärung von WhatsApp finden Sie hier: <a href="https://www.whatsapp.com/legal/#privacy-policy" target="_blank" rel="noopener noreferrer">https://www.whatsapp.com/legal/#privacy-policy</a></p>
        <h2 id="osm"><span style={{color: '#CDCDCD'}}>12.  OpenStreetMap</span></h2>
        <p>Wir binden Landkarten des Dienstes "OpenStreetMap" ein, die auf Grundlage der Open Data Commons Open Database Lizenz (ODbL) durch die OpenStreetMap Foundation (OSMF) angeboten werden.</p>
        <p>Die Datenschutzerklärung der OpenStreetMap Foundation finden Sie hier: https://wiki.osmfoundation.org/wiki/Privacy_Policy.</p>
        <p>
          Diese Seite bezieht die Karteninhalte von https://www.openstreetmap.de, welche von FOSSGIS e.V., Römerweg 5, 79199 Kirchzarten betrieben wird.
          Die Datenschutzerklärung des FOSSGIS e.V. finden Sie hier: https://www.fossgis.de/datenschutzerklaerung.
        </p>
        <p>
          Nach unserer Kenntnis werden Ihre Daten durch OpenStreetMap ausschließlich zu Zwecken der Darstellung der Kartenfunktionen und Zwischenspeicherung der gewählten Einstellungen verwendet. Zu diesen Daten können insbesondere gehören:
          • IP-Adresse zur Auslieferung der Daten, die Speicherung in Logs erfolgt anonymisiert,
          • Browser und Gerätetyp,
          • Betriebssystem,
          • der Umstand, dass Sie von dieser Webseite aus Inhalte bei OpenStreetMap abrufen, und
          • Datum und Uhrzeit der Anfrage.
          Diese Daten werden ausschließlich auf Servern in der Bundesrepublik Deutschland verarbeitet. Eine Freischaltung der Kartendarstellung und Kommunikation mit den OpenStreetMap Servern erstfolgt erst nach ihrer Freigabe.
        </p>
        <h2><span style={{color: '#CDCDCD'}}>13.  Links</span></h2>
        <p>Auf dieser Website empfehle ich Ihnen Internetauftritte anderer Unternehmen und Institutionen, auf die ich verlinke. Für  diese Seiten bin ich nicht verantwortlich und ich habe daher keinen Einfluss auf die Einhaltung des Datenschutzes dort. Sollten Sie Fragen zum Datenschutz auf Websites Dritter haben, informieren Sie sich bitte dort.</p>
        <h2><span style={{color: '#CDCDCD'}}>14.  Social Media</span></h2>
        <p>Auf dieser Website empfehle ich Ihnen Internetauftritte anderer Unternehmen und Institutionen, auf die ich verlinke. Für  diese Seiten bin ich nicht verantwortlich und ich habe daher keinen Einfluss auf die Einhaltung des Datenschutzes dort. Sollten Sie Fragen zum Datenschutz auf Websites Dritter haben, informieren Sie sich bitte dort.</p>
        <h3>Social Media Links</h3>
        <h4>Allgemeine Hinweise</h4>
        <p>
          Diese Website verweist mit Links auf unsere angebotenen Social Media Auftritte.
          Anders als Social Media Plugins führen Links nicht bereits bei Aufruf unserer Seite dazu, dass die Social Media Plattform von Ihrem Besuch erfährt.
          Wie jeder Link führen Sie aber spätestens dann dazu, dass Ihre Daten von der Social Media Plattform verarbeitet werden, wenn Sie den Link betätigen.
          Die Social Media Plattform wird im Regelfall Cookies auf Ihrem Gerät speichern oder sogar Ihr Nutzungsverhalten zu Ihrem Account speichern, insbesondere, wenn Sie selbst angemeldet sind.
          Die Social Media Plattform kann mit Ihren Daten Ihr Nutzerverhalten analysieren und diese für (interessengerechte) Werbung verwenden.
          Das kann dazu führen, dass Ihnen innerhalb und außerhalb der Social Media Plattform Werbung angezeigt wird.
          Bitte machen Sie sich selbst klar, ob Sie dies in Kauf nehmen wollen und nutzen Sie eine Social Media Plattform nur, wenn Sie sich über die Datenverarbeitung dort hinreichend informiert haben und damit einverstanden sind
          (das gilt insbesondere, wenn Sie selbst diese bis jetzt noch nicht nutzen) – wir haben jeweils Links zu den Datenschutzerklärungen des Social Media Plattformen beigefügt.
        </p><h4>Facebook</h4><p>
          Diese Seite verwendet links auf unseren Auftritt in dem sozialen Netzwerk Facebook der Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA.
          Es ist nur ein normaler Link, wenn Sie unsere Seite aufrufen, erfährt Facebook also nicht von ihrem Besuch auf unserer Website.
          Wenn Sie den Link jedoch anklicken, werden Sie zu Facebook weiter geleitet, dadurch erfährt Facebook auch, dass Sie unsere Seite besucht hatten.
        </p>
        <p>Dadurch werden Ihre Daten in die USA weiter gegeben, Facebook ist aber bei privacy shield registriert und verpflichtet, die EU-Datenschutzregeln einzuhalten.</p>
        <p>
          Von der nach Betätigen des links möglichen Erhebung und Verwendung Ihrer Daten durch Facebook haben wir keine Kenntnis und darauf auch keinen Einfluss.
          Nähere Informationen können Sie gegebenenfalls der Datenschutzerklärung von Facebook unter <a href="http://de-de.facebook.com/policy.php" target="_blank" rel="noreferrer noopener nofollow">http://de-de.facebook.com/policy.php</a> entnehmen.
        </p>
        <h4>Twitter</h4><p>
          Diese Seite verwendet links auf unseren Auftritt in dem sozialen Netzwerk Twitter der Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07 IRLAND.
          Es ist nur ein normaler link, wenn Sie unsere Seite aufrufen, erfährt Twitter also nicht von ihrem Besuch auf unserer Website.
          Wenn Sie den link jedoch anklicken, werden Sie zu Twitter weiter geleitet, dadurch erfährt Twitter auch, dass Sie unsere Seite besucht hatten.
        </p>
        <p>Dadurch können Ihre Daten in die USA weiter gegeben werden, Twitter ist aber bei privacy shield registriert und verpflichtet, die EU-Datenschutzregeln einzuhalten.</p>
        <p>
          Von der nach Betätigen des links möglichen Erhebung und Verwendung Ihrer Daten durch Twitter haben wir keine Kenntnis und darauf auch keinen Einfluss.
          Nähere Informationen können Sie gegebenenfalls der Datenschutzerklärung von Twitter unter <a href="https://twitter.com/privacy?lang=de" target="_blank" rel="noreferrer noopener nofollow">https://twitter.com/privacy?lang=de</a> entnehmen.
        </p>
        <h4>Instagram</h4>
        <p>Diese Seite verwendet links auf unseren Auftritt in dem sozialen Netzwerk Instagram der Instagram LLC, jetzt<br /> Facebook Ireland Ltd.<br /> 4 Grand Canal Square<br /> Grand Canal Harbour</p>
        <p>
          Es ist nur ein normaler Link, wenn Sie unsere Seite aufrufen, erfährt Instagram also nicht von ihrem Besuch auf unserer Website. Wenn Sie den Link jedoch anklicken, werden Sie zu Instagram weiter geleitet, dadurch erfährt
          Instagram bzw. Facebook auch, dass Sie unsere Seite besucht hatten.
        </p>
        <p>Dadurch werden Ihre Daten in die USA weiter gegeben, Facebook ist aber bei privacy shield registriert und verpflichtet, die EU-Datenschutzregeln einzuhalten.</p>
        <p>
          Von der nach Betätigen des links möglichen Erhebung und Verwendung Ihrer Daten durch Instagram/Facebook haben wir keine Kenntnis und darauf auch keinen Einfluss.
          Nähere Informationen können Sie gegebenenfalls der Datenschutzerklärung von Instagram unter <a href="https://help.instagram.com/155833707900388" target="_blank" rel="noreferrer noopener nofollow">https://help.instagram.com/155833707900388</a>
          entnehmen.
        </p><h2><span style={{color: '#CDCDCD'}}>15.  Social Media - Fanpage(s)</span></h2>
        <h3><span style={{color: '#CDCDCD'}}>Allgemeine Hinweise zu Fanpages</span></h3>
        <p>
          Neben dieser Website nutzen wir noch Fanpage(s) auf Social Media Plattformen.
          Nachdem wir Sie oben bereits über die Datenverarbeitungen auf dieser Website aufgeklärt und wir danach insbesondere
          sicher gestellt haben, dass nicht allein Ihr Aufruf unserer Website bereits an die Social Media Plattform gesendet wird, möchten wir Sie im Einklang mit
          dem <a href="http://curia.europa.eu/juris/document/document.jsf?text=&docid=202543&pageIndex=0&doclang=DE&mode=req&dir=&occ=first&part=1&cid=298398" target="_blank" rel="noreferrer noopener nofollow">EuGH Urteil vom 5. Juni 2018 zu Social Media Präsenzen</a><br />
          über die Datenverarbeitung anlässlich des Besuches unserer Social Media Fanpage(s) aufklären:
        </p>
        <p>
          Wir präsentieren uns und unsere Angebote auf der Basis unseres berechtigten Interesses gem. Art. 6 Abs. 1 lit. f DSGVO an einer zeitgerechten Kundenkommunikation sowie zum Zwecke der Werbung und
          unsere Public Relations auf Social Media Fanpage(s).
        </p>
        <p>
          Unsere Social Media Fanpage(s) können Sie regelmäßig unabhängig davon im Internet aufrufen, ob Sie auf der entsprechenden Plattform selbst ein Nutzerkonto angelegt haben oder nicht.
          In beiden Fällen werden Ihre Daten jedoch durch die Social Media Plattform verarbeitet.
        </p>
        <p>
          Bei jedem Aufruf der Social Media Plattform wird Ihre IP-Adresse erfasst und gespeichert. Weiter wird im Regelfall ein Cookie auf Ihrer Seite gesetzt, der Ihren Besuch und weitere Daten über Ihren Besuch auf der
          Social Media Plattform speichert.
        </p>
        <p>
          Sind Sie zusätzlich auf der Social Media Plattform selbst angemeldet, wird Ihr Besuch ebenfalls durch Cookies oder andere technische Mittel erfasst und Ihrem Benutzerkonto zugeordnet.
          Dadurch ist die Social Media Plattform regelmäßig in der Lage, Ihr Nutzerverhalten zu analysieren.
          Dabei wird ein Nutzerprofil mit Ihren Interessen erstellt, dass der Social Media Plattform dazu dient, Ihnen ein interessengerechtes Nutzungserlebnis, vor allem aber auch interessenbezogene Werbung innerhalb und
          außerhalb der Social Media Plattform auszuspielen – gegebenenfalls sogar geräteübergreifend. Weiter können Ihre Daten für Marktforschung verwendet werden.
          Für die Einzelheiten verweisen wir auf die nachfolgenden Datenschutzerklärungen der von uns verwendeten Social Media Plattform(en).
        </p>
        <p>Regelmäßig können wir die Daten der Social Media Plattform ebenfalls nutzen, um Ihnen eine interessengerechte Nutzung unserer Fanpage zu ermöglichen oder Sie mit Werbung anzusprechen.</p>
        <p>
          Interagieren Sie mit unserer Fanpage und sind Sie auf der entsprechenden Social Media Plattform angemeldet, können wir nach den Nutzungsbedingungen der Social Media Seite grundsätzlich auch Ihr Nutzerprofil erkennen
          und Ihre Inhalte sehen.<br /> Sofern Sie dies nicht wünschen, verweisen wir für den generellen Umgang mit Cookies und ihre Deaktivierung auf unsere obige Darstellung unter der Überschrift „Cookies“.
          Dort haben wir allgemein aufgeführt, wie Sie die Speicherung von Cookies auf Ihren Geräten verhindern oder einschränken können.
          Weitere Informationen finden Sie nachfolgend zu der entsprechenden Social Media Plattform.
        </p><p>
          Wir sind nach dem angegebenen EuGH-Urteil vom 5.6.2018 für unsere Social Media Fanpage(s) immer gemeinsam mit der
          jeweiligen Social Media Plattform verantwortlich. Das heißt, dass Sie Ihre allgemeinen Rechte, die wir oben unter der Überschrift „Rechte der Nutzer“ detailliert aufgeführt haben, auch uns gegenüber geltend machen können.
          Hierfür verweisen wir auf unsere Kontaktangaben am Anfang dieser Datenschutzerklärung.
          Bitte beachten Sie aber, dass wir keine abschließende Kenntnis von dem genauen Umfang der Verarbeitung Ihrer Daten auf der Social Media Plattform haben und noch viel weniger Einfluss darauf ausüben können.
          Wir bitten Sie daher, sich wegen Ihrer Rechte sogleich an die entsprechende Social Media Plattform zu wenden.
        </p>
        <p>Wir belassen Ihre Daten grundsätzlich auf der Social Media Plattform und importieren diese nicht auf unsere Server. Sofern dies doch einmal der Fall sein sollte, gilt unsere allgemeine Information zur Speicherdauer.</p>
        <p>Weitere Informationen zu der Verarbeitung Ihrer Daten und der Speicherdauer auf der Social Media Plattform entnehmen Sie bitte der jeweiligen Datenschutzerklärung der Social Media Plattform, die wir nachfolgend aufgeführt haben.</p>
        <h3>Facebook</h3><p>Wir präsentieren uns auf der Social Media Plattform Facebook.</p><p>
          Gemeinsam Verantwortlicher<br /> Facebook Ireland Ltd.<br /> 4 Grand Canal Square<br /> Grand Canal Harbour<br /> Dublin 2 Ireland<br />
          <a href="https://www.facebook.com/help/contact/2061665240770586" target="_blank" rel="noopener nofollow noreferrer">Kontakt</a><br />
          <a href="https://www.facebook.com/help/contact/540977946302970" target="_blank" rel="noopener nofollow noreferrer">Kontakt Datenschutzbeauftragter </a>
        </p>
        <p>Rufen Sie unsere Fanpage bei Facebook auf, werden Ihre Daten in die USA weiter gegeben, Facebook ist aber bei privacy shield registriert und verpflichtet, die EU-Datenschutzregeln einzuhalten.</p>
        <p>
          Facebook erkennt in einer mit uns abgeschlossenen Vereinbarung <a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noreferrer noopener nofollow">https://www.facebook.com/legal/terms/page_controller_addendum</a><br />
          über unsere gemeinsame Verantwortung für die Verarbeitung der Daten seine primäre Verantwortung an und steht insbesondere dafür ein, dass Sie Ihre Rechte auf Auskunft wie im allgemeinen Teil geschildert, wahrnehmen können.
          Nähere Informationen können Sie der Datenschutzerklärung von Facebook unter <a href="http://de-de.facebook.com/policy.php" target="_blank" rel="noreferrer noopener nofollow">http://de-de.facebook.com/policy.php</a> entnehmen.
        </p>
        <p>
          Facebook stellt auch uns Statistiken im Rahmen der „Facebook Insights“ für eine zielgerichtete Werbeansprache zur Verfügung.
          Diese werden ohne unser Zutun erzeugt und uns zur Verfügung gestellt.
          Die Daten umfassen etwa Interaktionen mit unserer Seite wie „Gefällt mir“ Angaben, die Seitenaktivitäten, Videoansichten, Reichweite unserer Posts, Kommentare, Teilung von Inhalten, Klicks auf
          Angebote oder weitere Informationen auf unserer Fanpage sowie statistische Daten zu den Interagierenden wie Geschlecht, Herkunft nach Staat, Stadt und Sprache. Das gleiche gilt für eventuell von uns verwaltete Gruppen.
        </p>
        <p>Sie können die Verarbeitung Ihrer Daten auf Facebook in verschiedenen Hinsichten stark beeinflussen, wenn Sie angemeldet sind.</p>
        <p>
          Ihre „Chronik“, also was von Ihnen und Ihrer Seite sichtbar ist, können Sie hier einstellen:<br /> <a href="https://www.facebook.com/settings/?tab=timeline&privacy_source=timeline_gear_menu" target="_blank" rel="noreferrer noopener nofollow">
            https://www.facebook.com/settings/?tab=timeline&amp;privacy_source=timeline_gear_menu
          </a> .
        </p>
        <p>
          Weiter können Sie Ihre Werbeeinstellungen hier einstellen:<br />
          <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank" rel="noreferrer noopener nofollow">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a> .
        </p>
        <h3>Instagram</h3><p>Wir präsentieren uns auf der Social Media Plattform Instagram. Instagram gehört inzwischen zu Facebook.</p>
        <p>Gemeinsam Verantwortlicher<br /> Facebook Ireland Ltd.<br /> 4 Grand Canal Square<br /> Grand Canal Harbour<br /> Dublin 2 Ireland</p>
        <p><a href="https://www.facebook.com/help/contact/540977946302970" target="_blank" rel="noopener nofollow noreferrer">Kontakt Datenschutzbeauftragter</a></p>
        <p>Rufen Sie unser Profil bei Instagram auf, werden Ihre Daten in die USA weiter gegeben, Facebook ist aber bei privacy shield registriert und verpflichtet, die EU-Datenschutzregeln einzuhalten.</p>
        <p>
          Von der dann möglichen Erhebung und Verwendung Ihrer Daten durch Facebook und Instagram haben wir keine Kenntnis und darauf auch keinen Einfluss. Nähere Informationen können Sie der Datenschutzerklärung
          von Instagram unter <a href="https://help.instagram.com/519522125107875" target="_blank" rel="noreferrer noopener nofollow">https://help.instagram.com/519522125107875</a> entnehmen.
        </p>
        <p>
          Instagram und Facebook stellen auch uns Statistiken im Rahmen der „Facebook Insights“ für eine zielgerichtete Werbeansprache zur Verfügung. Diese werden ohne unser Zutun erzeugt und uns zur Verfügung gestellt.
          Die Daten umfassen etwa Interaktionen mit unserer Seite wie „Gefällt mir“ Angaben, die Seitenaktivitäten, Videoansichten, Reichweite unserer Posts, Kommentare, Teilung von Inhalten, Klicks auf
          Angebote oder weitere Informationen auf unserer Fanpage sowie statistische Daten zu den Interagierenden wie Geschlecht, Herkunft nach Staat, Stadt und Sprache.
          Das gleiche gilt für eventuell von uns verwaltete Gruppen.
        </p>
        <p>
          Sie können die Verarbeitung Ihrer Daten auf Instagram in verschiedenen Hinsichten stark beeinflussen, wenn Sie angemeldet sind.<br />
          Ihre Inhalte, also was von Ihnen und Ihrem Profil sichtbar ist, können Sie hier einstellen:
          <a href="https://www.instagram.com/accounts/login/?next=/accounts/privacy_and_security/" target="_blank" rel="noreferrer noopener nofollow">https://www.instagram.com/accounts/login/?next=/accounts/privacy_and_security/</a>.
        </p>
        <h3>Twitter</h3>
        <p>Wir präsentieren uns auf der Social Media Plattform Twitter.</p>
        <p>
          Gemeinsam Verantwortlicher<br />
          Twitter International Company<br /> Attn: Data Protection Officer<br /> One Cumberland Place, Fenian Street<br /> Dublin 2, D02 AX07 IRLAND<br />
          <a href="https://help.twitter.com/forms/privacy" target="_blank" rel="noopener nofollow noreferrer">Kontakt</a>
        </p>
        <p>Rufen Sie unseren Kanal bei Twitter auf, können Ihre Daten in die USA weiter gegeben, Twitter ist aber bei privacy shield registriert und verpflichtet, die EU-Datenschutzregeln einzuhalten.</p>
        <p>
          Von der dann möglichen Erhebung und Verwendung Ihrer Daten durch Twitter haben wir keine Kenntnis und darauf auch keinen Einfluss.
          Nähere Informationen können Sie der Datenschutzerklärung von Twitter unter <a href="https://twitter.com/de/privacy" target="_blank" rel="noreferrer noopener nofollow">https://twitter.com/de/privacy</a><br /> entnehmen.
        </p>
        <p>Twitter stellt auch uns Statistiken zur Verfügung. Diese werden ohne unser Zutun erzeugt und uns zur Verfügung gestellt. Die Daten umfassen etwa Interaktionen sowie statistische Daten.</p>
        <p>Sie können die Verarbeitung Ihrer Daten auf Twitter in verschiedenen Hinsichten stark beeinflussen, wenn Sie angemeldet sind.</p><p>
          Ihre Privatsphäre bei Twitter können Sie hier einstellen:<br />
          <a href="https://twitter.com/personalization" target="_blank" rel="noreferrer noopener nofollow">https://twitter.com/personalization</a>.
        </p>
        <h2><span style={{color: '#CDCDCD'}}>16.  Ihr Recht auf Auskunft</span></h2>
        <p>Sie haben gemäß Art. 15 DSGVO das Recht, von mir eine Bestätigung darüber zu verlangen, ob ich Sie betreffende personenbezogene Daten verarbeite. Wenn dies der Fall ist, haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf folgende Informationen:</p>
        <ul>
          <li>die Verarbeitungszwecke,</li>
          <li>die Kategorien personenbezogener Daten, die verarbeitet werden,</li>
          <li>die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offen gelegt worden sind oder noch offen gelegt werden,</li>
          <li>falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer,</li>
          <li>das Bestehen eines Rechts auf Berichtigung oder Löschung Ihrer personenbezogenen Daten, auf Einschränkung der Verarbeitung oder das Bestehen eines Widerspruchsrechts gegen diese Verarbeitung,</li>
          <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde,</li>
          <li>wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten,</li>
          <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und gegebenenfalls aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für Sie.</li>
        </ul>
        <p>Wenn personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt werden, haben Sie das Recht, über die geeigneten Garantien gemäß Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.</p>
        <h2><span style={{color: '#CDCDCD'}}>17.  Ihr Recht auf Berichtigung</span></h2>
        <p>Sie haben gemäß Art. 16 DSGVO das Recht, von mir unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen, und Sie haben unter Berücksichtigung der Zwecke der Verarbeitung das Recht, die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.</p>
        <h2><span style={{color: '#CDCDCD'}}>18.  Ihr Recht auf Löschung</span></h2>
        <p>Sie haben gemäß Art. 17 DSGVO das Recht von mir zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und ich bin verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der in Art. 17 Absatz 1  DSGVO genannten Gründe zutrifft, zum Beispiel wenn die personenbezogenen Daten unrechtmäßig verarbeitet wurden.</p>
        <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, insbesondere zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
        <h2><span style={{color: '#CDCDCD'}}>19.  Ihr Recht auf Einschränkung der Verarbeitung</span></h2>
        <p>Sie haben gemäß Art. 18 DSGVO das Recht, von mir die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:</p>
        <ul>
          <li>die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es mir ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,</li>
          <li>die Verarbeitung ist unrechtmäßig und Sie lehnen die Löschung der personenbezogenen Daten ab und verlangen stattdessen die Einschränkung der Nutzung der personenbezogenen Daten,</li>
          <li>ich benötige die personenbezogenen Daten nicht länger für die Zwecke der Verarbeitung, Sie benötigen die Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen, oder</li>
          <li>Sie haben Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt, solange noch nicht feststeht, ob meine berechtigten Gründe gegenüber Ihren Gründen überwiegen.</li>
        </ul>
        <h2><span style={{color: '#CDCDCD'}}>20.  Ihr Recht auf Datenübertragbarkeit</span></h2>
        <p>Sie haben gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie mir bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie können auch von mir verlangen, dass ich die personenbezogenen Daten direkt einem anderen Verantwortlichen übermittele, soweit dies technisch möglich ist.</p>
        <h2><span style={{color: '#CDCDCD'}}>21.  Ihr Recht auf Beschwerde bei einer Aufsichtsbehörde</span></h2>
        <p>Gemäß Art. 77 DSGVO haben Sie unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer  personenbezogenen Daten gegen die DSGVO verstößt.</p>
        <h2><span style={{color: '#CDCDCD'}}>22.  Ihr Recht auf Widerruf der Einwilligung</span></h2>
        <p>Soweit Sie mir Ihre Einwilligung zu der Verarbeitung der Sie betreffenden personenbezogenen Daten gemäß Art. 6 Abs. 1 S. 1 a DSGVO gegeben haben, haben Sie gemäß Art. 7 Abs. 3 DSGVO das Recht, Ihre Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird durch den Widerruf nicht berührt.</p>
        <h2><span style={{color: '#CDCDCD'}}>23.  Ihr Widerspruchsrecht</span></h2>
        <p><strong>Soweit ich Ihre personenbezogenen Daten zur Wahrung meiner berechtigten Interessen gemäß Art. 6 Absatz 1 S. 1 f DSGVO verarbeite, haben Sie gemäß Art. 21 DSGVO das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Datenverarbeitung Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling. Ich verarbeite die personenbezogenen Daten nicht mehr, es sei denn, ich kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</strong></p>
        <p><strong>Werden personenbezogene Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong></p>
        <p><strong>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</strong></p>
        <p><strong>Um Ihr Widerspruchsrecht auszuüben, senden Sie bitte eine E-Mail an: </strong> <strong>info@estetiese.de</strong></p>
        <h2><span style={{color: '#CDCDCD'}}>24.  Bereitstellung Ihrer personenbezogenen Daten</span></h2>
        <p>Die Bereitstellung Ihrer personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Sie sind nicht dazu verpflichtet, mir personenbezogene Daten bereit zu stellen. Wenn Sie mit mir einen Vertrag abschließen, sind bestimmte personenbezogene Daten wie zum Beispiel Ihre Kontaktdaten für den Vertragsabschluss erforderlich. Wenn Sie mir diese Daten nicht mitteilen, werde ich den Vertrag mit Ihnen nicht schließen.</p>
        <h2><span style={{color: '#CDCDCD'}}>25.  Bestehen einer automatisierten Entscheidungsfindung</span></h2>
        <p>Es wird keine automatisierte Entscheidungsfindung und kein Profiling verwendet.</p>
        <h2><span style={{color: '#CDCDCD'}}>26.  Künftige Änderungen der Datenschutzerklärung</span></h2>
        <p>Diese Datenschutzerklärung ist aktuell gültig. Künftige Änderungen behalte ich mir vor. Diese liegen spätestens mit Inkrafttreten der ePrivacy-Verordnung (voraussichtlich in 2019) auf der Hand. Möglicherweise wird die Datenschutzerklärung auch schon früher überarbeitet, zum Beispiel, wenn ich einen neuen Dienst auf meiner Website integriere (oder einen alten Dienst entferne). Es macht also durchaus Sinn, hin und wieder einen Blick in diese Datenschutzerklärung zu werfen. Die jeweils aktuelle Fassung finden Sie auf meiner Website unter dem Link „Datenschutzerklärung“.</p>
        <p>Stand: Bremen, November 2019</p>
        <p style={{textAlign: 'left'}}><a title="Impressum & Datenschutzerklärung" href="#datenschutz">zurück zur Übersicht</a></p>
        
        <h1><span style={{color: '#CDCDCD'}}>Sonstiges</span></h1>
        <p>        
          <span style={{color: '#CDCDCD'}}>
            <b>Copyright</b><b>
              <br />
            </b>
          </span>Alle Texte und Fotos © {publisher}, soweit nicht von 
          {/* <a title="Bildrechte"  href="#Bildrechte">Dritten lizenziert</a>. */}
          <Link to="/impressum/#Bildrechte"> Dritten lizenziert</Link>.
          <br />
          Alle Rechte vorbehalten.
        </p>
        <p>
          <span style={{color: '#CDCDCD'}}><b>Haftung</b><b /></span><br />
          Die von mir erstellten Beiträge habe ich zu Informationszwecken gewissenhaft recherchiert. Die Bereitstellung der Informationen begründet und ersetzt keine medizinische Diagnose im Einzelfall. Eine Haftung für etwaige Fehler und Irrtümer wird daher nicht übernommen.
        </p>
        <p>
          <span style={{color: '#CDCDCD'}}>
            <b>Links</b><b>
              <br />
            </b>
          </span>Fremde Seiten im Internet, auf die mittels Hyperlinks verwiesen wird, stellen eine Empfehlung für Sie dar. Sollten Sie von Seiten Kenntnis erlangen, auf die ich mittels Hyperlink verweise und auf denen Sie rechtswidrige Inhalte entdecken, informieren Sie mich bitte, damit ich den Link entferne.
        </p>
        <p />
      </article>
        </div>
           
        </Typography>
      </Container>
    </Layout>
  )
}

Datenschutz.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Datenschutz)

